import Header from "../components/Header";
import Footer from "../components/Footer";
import Title from "../components/Title";

function WhoAmI() {
    return (
        <div className="container text-white">
            <Header />
            <Title
                title="Who Am I?"
                description="A glimpse into the mind behind the screen. A fusion of code, creativity, and curiosity."
            />

            <div className="content">
                <p>
                    <hr className="my-3" />
                    <em>System booting... Accessing user data... Decrypting...</em>
                    <hr className="my-3" />
                </p>
                <p>
                    Name? Irrelevant. Identity? A blend of code, circuits, and raw curiosity.
                </p>
                <p>
                    I build. I break. I rebuild. Code runs through my veins, but I'm no corporate drone. I craft self-hosted systems, bending networks to my will, securing communication in a world on the brink. Decentralized, resilient, off-grid—because control belongs to the user.
                </p>
                <p>
                    By day, a full-stack developer. By night, a racer—both digital and real. Karting, sim racing, chasing milliseconds on the Nürburgring. Engineering the perfect trail-brake, searching for the line between chaos and control.
                </p>
                <p>
                    Art? It’s not just pixels and syntax. I capture light through the lens, freeze thoughts in words, script futures that haven't happened yet. Philosophy, dystopia, the human condition—all fuel for the machine.
                </p>
                <p>
                    But film? That’s the real ghost in the machine. Not just stories, but worlds. Not just frames, but windows into alternate realities. I don’t just watch—I deconstruct, analyze, and build. A first film is in the works. No studio execs. No compromises. Just raw vision, burned onto the timeline.
                </p>
                <p>
                    Welcome to the archive. Browse at your own risk.
                </p>

                <p>
                    <hr className="my-3" />
                    <em>End of transmission...</em>
                    <hr className="my-3" />
                </p>
            </div>

            <Footer />
        </div>
    );
}

export default WhoAmI;
  
